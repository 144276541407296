<template>

	<div class="page" id="contact">

		<div class="page-heading">
			<h2>Want to know more?</h2>
			<h1>Get in touch!</h1>
		</div>

		<div class="container">
			<div class="row no-gutters">
				<div class="col-12 col-lg-8 order-lg-1">

					<div class="px-3 py-5">

						<div class="form-group">
							<label for="form-name">Your name</label>
							<input type="text" class="form-control" id="form-name"/>
						</div>

						<div class="form-group">
							<label for="form-email">E-mailaddress</label>
							<input type="text" class="form-control" id="form-email"/>
						</div>

						<div class="form-group">
							<label for="form-question">Message</label>
							<textarea class="form-control" id="form-question" rows="5"></textarea>
						</div>

						<div class="form-group">
							<button class="btn btn-contained btn-primary"><i class="mdi mdi-check-circle"></i><span>Submit</span></button>
						</div>

					</div>

				</div>
				<div class="col-12 col-lg-4 order-lg-0">

					<div class="row no-gutters block-layout">
						<div class="col-12 block-item block-accent block-image full-content">

							<img src="../assets/contact/facebook.jpg" alt="Photo 1"/>

							<div class="block-content text-white w-100">
								<h4>Find us on</h4>
								<h3 class="text-primary">Facebook</h3>
								<button class="btn btn-contained btn-primary"><span>Visit Facebook</span><i class="mdi mdi-arrow-right-bold-circle"></i></button>
							</div>

						</div>
					</div>

					<div class="row no-gutters block-layout">
						<div class="col-12 block-item block-accent block-image full-content">

							<img src="../assets/contact/instagram.jpg" alt="Instagram"/>

							<div class="block-content text-white w-100">
								<h4>See us on</h4>
								<h3 class="text-primary">Instagram</h3>
								<button class="btn btn-contained btn-primary"><span>Visit Instagram</span><i class="mdi mdi-arrow-right-bold-circle"></i></button>
							</div>

						</div>
					</div>

				</div>
			</div>
		</div>

	</div>

</template>

<script>

	export default {

		name: "contact"

	}

</script>

<style lang="scss" scoped>

</style>
